import React from "react";

import Loading from "../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { EmbeddedDashboard } from "../components/EmbeddedDashboard";

const DashboardComponent = (props) => {
  const { dashboardId } = props;
  return (
    <>
      <EmbeddedDashboard dashboardId={dashboardId} />
    </>
  );
};

export default withAuthenticationRequired(DashboardComponent, {
  onRedirecting: () => <Loading />,
});
