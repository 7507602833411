import React from "react";

import Loading from "../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import OrganizationPicker from "../components/OrganizationPicker";

const Organizations = () => {
  return (
    <>
      <OrganizationPicker />
    </>
  );
};

export default withAuthenticationRequired(Organizations, {
  onRedirecting: () => <Loading />,
});
