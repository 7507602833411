import { useAuth0 } from "@auth0/auth0-react";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import UserMenuButton from "./Buttons/UserMenuButton";
import NavigationButton from "./Buttons/NavigationButton";
import { ROUTES } from "../constants";

const NavBar = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const pages = [
    { title: "Dashboard", route: "/dashboard" },
    { title: "Locations", route: ROUTES.LOCATIONS_DASHBOARD },
    { title: "Explore", route: "/explore" },
  ];

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}
          >
            Noonlight
          </Typography>

          {isAuthenticated ? (
            <>
              <Box sx={{ flexGrow: 1 }}>
                {pages.map((page) => (
                  <React.Fragment key={page.route}>
                    <NavigationButton size={"large"} route={page.route}>
                      {page.title}
                    </NavigationButton>
                  </React.Fragment>
                ))}
              </Box>
              <UserMenuButton />
            </>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => loginWithRedirect()}
            >
              Log in
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Divider />
    </>
  );
};

export default NavBar;
