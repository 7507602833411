import { Box } from "@mui/system";
import React from "react";

const Item = (props) => {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        textAlign: "center",
        ...sx,
      }}
      {...other}
    />
  );
};

export { Item };
