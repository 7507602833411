// use-api.js
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";
const { audience } = getConfig();

export const useApi = (url) => {
  const { getAccessTokenSilently } = useAuth0();
  const [state, setState] = useState({
    error: null,
    loading: true,
    data: null,
  });

  useEffect(() => {
    (async () => {
      try {
        // const { ...fetchOptions } = options;
        const accessToken = await getAccessTokenSilently({ audience });
        const res = await fetch(url, {
          // ...fetchOptions,
          headers: {
            // ...fetchOptions.headers,
            // Add the Authorization header to the existing headers
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setState({
          data: await res.json(),
          error: null,
          loading: false,
        });
      } catch (error) {
        setState({
          data: undefined,
          error,
          loading: false,
        });
      }
    })();
  }, [getAccessTokenSilently, url]);

  return {
    ...state,
  };
};
