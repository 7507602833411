import React, { useEffect, useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import { useApi } from "../hooks/useApi";
import { useLocalStorage } from "beautiful-react-hooks";
import { STORAGE_KEYS } from "../constants";
import { getConfig } from "../config";
import { useHistory } from "react-router-dom";
import Loading from "./Loading";
import { useAuth0 } from "@auth0/auth0-react";
import DropdownSelectionConfirmation from "./Pickers/DropdownSelectionConfirmation";

const { audience } = getConfig();
const options = {
  audience,
};
const OrganizationPicker = () => {
  useHistory();
  const { loginWithRedirect } = useAuth0();

  const {
    loading,
    error: fetchError,
    data,
  } = useApi("/api/me/organizations", options);
  const [organizations, setOrganizations] = useState([]);

  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [error, setError] = useState(false);

  const [storedOrgId, setStoredOrgId] = useLocalStorage(STORAGE_KEYS.ORG_ID);

  useEffect(() => {
    if (data && data.length > 0) {
      const orgs = data.map((org) => {
        return {
          id: org.id,
          label: org.display_name,
        };
      });
      setOrganizations(orgs);
      setSelectedOrganization(storedOrgId ?? orgs[0].id);
    } else {
      setOrganizations([]);
      if (!loading && !fetchError) {
        setError(true); //no orgs found error
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleChange = (newValue) => {
    // console.log(`Handle change: ${newValue}`);

    setSelectedOrganization(newValue);
  };

  const handleSubmit = async () => {
    // console.log(`Handle submit: ${selection}`);
    // console.log("Is setting org ID for first time");
    //if org ID hasn't been set yet, set it & then hard refresh to update Auth0 provider in index.js
    await setStoredOrgId(selectedOrganization);
    await loginWithRedirect({
      organization: selectedOrganization,
    });
    // if (!isValidOrgId(storedOrgId)) {
    //   console.log("Is setting org ID for first time");
    //   //if org ID hasn't been set yet, set it & then hard refresh to update Auth0 provider in index.js
    //   await setStoredOrgId(selectedOrganization);
    //   await loginWithRedirect({
    //     organization: storedOrgId,
    //   });
    //   // window.location.reload();
    // } else {
    //   console.log("Using new org");
    //
    //   //otherwise, fetch a new token & go to /
    //
    //   const token = await getAccessTokenSilently({
    //     audience,
    //     ignoreCache: true,
    //   }); //force refresh token to get new token w/ Org
    //   // history.push("/");
    // }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {error ? (
        <>
          <Paper sx={{ width: "60%", mx: "auto", marginTop: 4 }}>
            <Box padding={5}>
              <Typography variant="h4" align={"center"}>
                Your account is pending approval.
              </Typography>
              <Typography variant="body1" align={"center"}>
                Once your account has been approved you will be able to complete
                your setup on this page. Please contact your administrator or
                info@noonlight.com for more information.
              </Typography>
            </Box>
          </Paper>
        </>
      ) : (
        <Box sx={{ p: 4, width: "40%", mx: "auto" }}>
          <DropdownSelectionConfirmation
            title={"Select organization:"}
            items={organizations}
            onChange={handleChange}
            onSubmit={handleSubmit}
            // defaultSelectedId={"org_id"}
          />
        </Box>
      )}
    </>
  );
};
export default OrganizationPicker;
