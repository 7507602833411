import React, { useEffect, useState } from "react";
import {
  Button,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { arrayContainsItemWithId } from "../../utils/array";

//items should be array of objects w/ `id` and `label` keys. eg [{id: 1, label: "Label"}, ...]
function DropdownSelectionConfirmation({
  title,
  items,
  onChange,
  onSubmit,
  //optional. If not provided, will default to first item in items array
  defaultSelectedId,
}) {
  const [selection, setSelection] = useState();

  useEffect(() => {
    if (!items || items.length === 0) {
      return;
    }
    if (defaultSelectedId) {
      //Default value setup: defaultValue if exists, otherwise first object in array, otherwise emtpy
      //check if items array contains defaultValue
      if (arrayContainsItemWithId(items, defaultSelectedId)) {
        setSelection(defaultSelectedId);
      } else {
        console.error(
          `Default selected ID of ${defaultSelectedId} not found in items array`
        );
      }
    } else {
      const { id } = items[0];
      setSelection(id);
    }
  }, [defaultSelectedId, items]);

  const handleChange = (e) => {
    const { value: newValue } = e.target;
    setSelection(newValue);

    if (onChange) {
      return onChange(newValue);
    }
  };

  const handleSubmit = () => {
    if (onSubmit) {
      return onSubmit(selection);
    }
  };

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <Stack spacing={2}>
          <Typography variant="h6">{title}</Typography>
          <Select
            sx={{ width: "100%" }}
            value={selection ?? defaultSelectedId ?? ""}
            onChange={handleChange}
          >
            {items.map((item) => {
              const { id, label } = item;
              return (
                <MenuItem key={id} value={id}>
                  {label}
                </MenuItem>
              );
            })}
          </Select>
          <Button
            onClick={handleSubmit}
            disabled={!selection}
            variant={"contained"}
          >
            Confirm
          </Button>
        </Stack>
      </Paper>
    </>
  );
}

export default DropdownSelectionConfirmation;
