import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";
import { getConfig } from "./config";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { STORAGE_KEYS } from "./constants";
import { CssBaseline } from "@mui/material";
import { isValidOrgId } from "./utils/organizations";

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

// Please see https://auth0.github.io/auth0-react/interfaces/auth0_provider.auth0provideroptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const orgId = localStorage.getItem(STORAGE_KEYS.ORG_ID);

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  redirectUri: window.location.origin,
  // cacheLocation:
  //   process.env.NODE_ENV === "development" ? "localstorage" : "memory", //'localstorage', //TODO: Remove - temp solution for development in brave browser when refreshing persist login
  useRefreshTokens: true,
  onRedirectCallback,
};
if (isValidOrgId(orgId)) {
  providerConfig.organization = orgId?.replace(/["]+/g, "");
}
// console.log(providerConfig);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Auth0Provider {...providerConfig}>
        <CssBaseline />
        <App />
      </Auth0Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
