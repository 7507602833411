import React, { useEffect, useRef, useState } from "react";
import { LookerEmbedSDK } from "@looker/embed-sdk";
import { EmbeddedContent } from "./EmbeddedContent";
import { useAuth0 } from "@auth0/auth0-react";
import { LOOKER_STATES } from "../constants";
import { Box } from "@mui/material";
import Loading from "./Loading";
import { getConfig } from "../config";
const { audience, lookerHost } = getConfig();

const EmbeddedDashboard = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const lookerRef = useRef();

  const [lookerState, setLookerState] = useState(LOOKER_STATES.LOADING);
  const { dashboardId } = props;

  useEffect(() => {
    const setupLookerDashboard = async () => {
      try {
        const token = await getAccessTokenSilently({ audience });
        await LookerEmbedSDK.init(lookerHost, {
          url: "/api/auth",
          headers: [{ name: "Authorization", value: `Bearer ${token}` }],
        });
        await LookerEmbedSDK.createDashboardWithId(dashboardId)
          .on("dashboard:loaded", updateLookerStateTo)
          .on("dashboard:run:start", updateLookerStateTo)
          .on("dashboard:run:complete", updateLookerStateTo)
          .on("drillmenu:click", canceller)
          .on("drillmodal:explore", canceller)
          .on("dashboard:tile:explore", canceller)
          .on("dashboard:tile:view", canceller)
          .withNext()
          .appendTo(lookerRef.current)
          .build()
          .connect()
          // .then(setupDashboard)
          .catch((error) => {
            console.error("Connection error", error);
            setLookerState(LOOKER_STATES.ERROR);
          });
      } catch (error) {
        console.log(error);
        setLookerState(LOOKER_STATES.ERROR);
      }
    };
    setupLookerDashboard();
  }, [dashboardId, getAccessTokenSilently]);

  const canceller = (event) => {
    return { cancel: !event.modal };
  };

  const updateLookerStateTo = (event) => {
    const { type: toState } = event;
    if (toState === "dashboard:loaded") {
      setLookerState(LOOKER_STATES.LOADED);
    } else if (toState === "dashboard:run:start") {
      setLookerState(LOOKER_STATES.RUNNING);
    } else if (toState === "dashboard:run:complete") {
      setLookerState(LOOKER_STATES.DONE);
    }
  };

  return (
    <>
      <EmbeddedContent ref={lookerRef} />
      {LOOKER_STATES.LOADING === lookerState && (
        <Box
          sx={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }}
        >
          <Loading />
        </Box>
      )}
    </>
  );
};

export { EmbeddedDashboard };
