import { useAuth0 } from "@auth0/auth0-react";
import { Box, Container } from "@mui/material";
import React from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
// styles
import "./App.css";
import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import history from "./utils/history";
// fontawesome
import DashboardComponent from "./views/Dashboard";
import ExploreComponent from "./views/Explore";
import Home from "./views/Home";
import Profile from "./views/Profile";
import Organizations from "./views/Organizations";
import { useLocalStorage } from "beautiful-react-hooks";
import { ROUTES, STORAGE_KEYS } from "./constants";
import OrgProtectedRoute from "./components/Routes/OrgProtectedRoute";

const HomeDashboard = () => {
  return <DashboardComponent dashboardId={207} />;
};

const LocationsDashboard = () => {
  return <DashboardComponent dashboardId={211} />;
};

const App = () => {
  const { isLoading, error, loginWithRedirect, isAuthenticated } = useAuth0();
  const [, setOrgId] = useLocalStorage(STORAGE_KEYS.ORG_ID);
  const url = window.location.href;
  const inviteMatches = url.match(/invitation=([^&]+)/);
  const orgMatches = url.match(/organization=([^&]+)/);
  if (inviteMatches && orgMatches) {
    setOrgId(orgMatches[1]);
    loginWithRedirect({
      organization: orgMatches[1],
      invitation: inviteMatches[1],
    });
  }

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Loading />;
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Router history={history}>
        <Box>
          <NavBar />
        </Box>

        <Container disableGutters>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100vh",
            }}
          >
            <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => {
                    return isAuthenticated ? (
                      <Redirect to="/dashboard" />
                    ) : (
                      <Redirect to="/home" />
                    );
                  }}
                />
                <Route path="/organizations" component={Organizations} />
                <Route path="/home" exact component={Home} />
                <Route path="/profile" component={Profile} />
                <OrgProtectedRoute
                  path="/dashboard"
                  component={HomeDashboard}
                />
                <OrgProtectedRoute
                  path={ROUTES.LOCATIONS_DASHBOARD}
                  component={LocationsDashboard}
                />
                <OrgProtectedRoute
                  path="/explore"
                  component={ExploreComponent}
                />
              </Switch>
            </Box>
          </Box>
        </Container>
      </Router>
    </Box>
  );
};

export default App;
