import { createTheme } from "@mui/material/styles";

// https://bareynol.github.io/mui-theme-creator/#List

export const themeOptions = {
  palette: {
    type: "light",
    primary: {
      main: "#337ab7",
    },
    secondary: {
      main: "#337ab7",
    },
    error: {
      main: "#d9534f",
    },
    warning: {
      main: "#f0ad4e",
      contrastText: "#ffffff",
    },
    success: {
      main: "#5cb85c",
      contrastText: "#ffffff",
    },
    text: {
      primary: "#212529",
    },
    info: {
      main: "#17a2b8",
    },
    common: {
      lightGrey: "#f5f5f5",
    },
    notes: {
      main: "#fcf8e3",
    },
    events: {
      main: "#f5f5f5",
    },
    sms: {
      incoming: {
        main: "#E8F5E9",
      },
      outgoing: {
        main: "#E3F2FD",
      },
    },
  },
  shadows: [
    "none",
    "0px 1px 3px 0px rgba(0,0,0,0.12)",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
  ],
};

themeOptions.components = {
  MuiCircularProgress: {
    styleOverrides: {
      indeterminate: {
        animationDuration: "550ms",
      },
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        padding: "10px",
        backgroundColor: "#f5f5f5",
      },
      action: {
        marginTop: 0,
        marginRight: 0,
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        padding: "10px",
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      colorInherit: {
        backgroundColor: "#689f38",
        color: "#fff",
      },
    },
    defaultProps: {
      color: "default",
    },
  },
  MuiButtonBase: {
    defaultProps: {
      // The props to apply
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: "none",
        fontWeight: "normal",
      },
    },
    defaultProps: {
      size: "small",
    },
  },
  MuiButtonGroup: {
    defaultProps: {
      size: "small",
    },
  },
  MuiCheckbox: {
    defaultProps: {
      size: "small",
    },
  },
  MuiFab: {
    defaultProps: {
      size: "small",
    },
  },
  MuiFormControl: {
    defaultProps: {
      margin: "dense",
      size: "small",
    },
  },
  MuiFormHelperText: {
    defaultProps: {
      margin: "dense",
    },
  },
  MuiIconButton: {
    defaultProps: {
      size: "small",
    },
  },
  MuiInputBase: {
    defaultProps: {
      margin: "dense",
    },
  },
  MuiInputLabel: {
    defaultProps: {
      margin: "dense",
    },
  },
  MuiRadio: {
    defaultProps: {
      size: "small",
    },
  },
  MuiSwitch: {
    defaultProps: {
      size: "small",
    },
  },
  MuiTextField: {
    defaultProps: {
      margin: "dense",
      size: "small",
    },
  },
};

const DefaultTheme = createTheme(themeOptions);

export default DefaultTheme;
