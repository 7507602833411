import React, { useMemo } from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocalStorage } from "beautiful-react-hooks";
import { STORAGE_KEYS, ROUTES } from "../../constants";
import { isValidOrgId } from "../../utils/organizations";

function OrgProtectedRoute({ component: Component, ...restOfProps }) {
  const { user } = useAuth0();
  const [orgId] = useLocalStorage(STORAGE_KEYS.ORG_ID);

  const hasStoredOrgId = useMemo(() => isValidOrgId(orgId), [orgId]);
  const userAuthedWithOrgId = useMemo(() => {
    return isValidOrgId(user.org_id);
  }, [user.org_id]);

  const isOrgAuthorized = hasStoredOrgId && userAuthedWithOrgId;

  return (
    <Route
      {...restOfProps}
      render={(props) => {
        return isOrgAuthorized ? (
          <Component {...props} />
        ) : (
          <Redirect to={ROUTES.ORGANIZATIONS} />
        );
      }}
    />
  );
}

export default OrgProtectedRoute;
