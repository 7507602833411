import styled from "styled-components";

export const EmbeddedContent = styled.div`
  width: 100%;
  height: 100vh;
  & > iframe {
    width: 100%;
    height: 100%;
  }
`;
