import React, { Fragment } from "react";




const Home = () => (
  <Fragment>
  </Fragment>
);

export default Home;
