import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useHistory, useRouteMatch } from "react-router-dom";

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => {
    return prop !== "active";
  },
})(({ active, theme }) => {
  // console.log(active);
  return {
    ...(active && {
      fontWeight: theme.typography.fontWeightBold,
    }),
    // fontWeight: active
    //   ? theme.typography.fontWeightBold
    //   : theme.typography.fontWeightRegular,
  };
});

export default function NavigationButton({ route, ...rest }) {
  const match = useRouteMatch(route);
  const history = useHistory();

  const onClick = () => {
    history.push(route);
  };

  return <StyledButton onClick={onClick} active={match} {...rest} />;
}

// export default NavigationButtonStyle;
//
//   return (
//     <Stack spacing={2} direction="row">
//       <ColorButton variant="contained">Custom CSS</ColorButton>
//       <BootstrapButton variant="contained" disableRipple>
//         Bootstrap
//       </BootstrapButton>
//     </Stack>
//   );
// }
