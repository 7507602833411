import React, { useEffect, useRef, useState } from "react";
import { LookerEmbedSDK } from "@looker/embed-sdk";
import { EmbeddedContent } from "./EmbeddedContent";
import { useAuth0 } from "@auth0/auth0-react";
import { LOOKER_STATES } from "../constants";
import { Box } from "@mui/material";
import Loading from "./Loading";
import { getConfig } from "../config";
import { useQueryParam } from "beautiful-react-hooks";

const { audience, lookerHost } = getConfig();

const EmbeddedExplore = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const lookerRef = useRef();
  const [lookerState, setLookerState] = useState(LOOKER_STATES.LOADING);
  const [, setExploreQueryId] = useQueryParam("qid", {
    initialValue: null,
    replaceState: false,
  });
  const { exploreId } = props;

  const getUrlQid = () => {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    let qid = params.get("qid");
    return qid;
  };

  useEffect(() => {
    const setupLookerExplore = async () => {
      try {
        const params = {};
        const qid = getUrlQid();
        if (qid) {
          params.qid = qid;
        }
        const token = await getAccessTokenSilently({ audience });
        await LookerEmbedSDK.init(lookerHost, {
          url: "/api/auth",
          headers: [{ name: "Authorization", value: `Bearer ${token}` }],
        });
        await LookerEmbedSDK.createExploreWithId(exploreId)
          .withParams(params)
          // Append to the #explore element
          // Listen to messages to display progress
          .on("explore:ready", () => setLookerState(LOOKER_STATES.LOADED))
          .on("explore:run:start", () => setLookerState(LOOKER_STATES.RUNNING))
          .on("explore:run:complete", () => setLookerState(LOOKER_STATES.DONE))
          .on("explore:state:changed", (x) => {
            const { absoluteUrl } = x.explore;
            const url = new URL(absoluteUrl);
            const params = new URLSearchParams(url.search);
            const queryId = params?.get("qid");
            setExploreQueryId(queryId);
          })
          .appendTo(lookerRef.current)
          .build()
          // Connect to Looker
          .connect()
          // Finish up setup
          // .then(setupExplore)
          // Log if something went wrong
          .catch((error) => {
            console.error("Connection error", error);
            setLookerState(LOOKER_STATES.ERROR);
          });
      } catch (error) {
        console.log(error);
        setLookerState(LOOKER_STATES.ERROR);
      }
    };
    setupLookerExplore();
  }, [exploreId, getAccessTokenSilently, setExploreQueryId]);

  return (
    <>
      <EmbeddedContent ref={lookerRef} />
      {LOOKER_STATES.LOADING === lookerState && (
        <Box
          sx={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }}>
          <Loading />
        </Box>
      )}
    </>
  );
};

export { EmbeddedExplore };
