import React from "react";

import Loading from "../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { EmbeddedExplore } from "../components/EmbeddedExplore";
import { Box } from "@mui/material";

const ExploreComponent = () => {
  return (
    <Box>
      <EmbeddedExplore
        exploreId={"noonlight_external::production_verifications"}
      />
    </Box>
  );
};

export default withAuthenticationRequired(ExploreComponent, {
  onRedirecting: () => <Loading />,
});
